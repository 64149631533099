import { AuthAction, withUser } from 'next-firebase-auth'
import React, { ReactElement } from 'react'

import { AuthEventsEnum, FINAL_DESTINATION_PARAM } from '~/analytics/AuthEvent'
import { LoginHome } from '~/components/auth/LoginHome'
import { Layout } from '~/components/layout/Layout'
import { FullPageLoader } from '~/components/shared/loading/ThatchLoader'
import { useAnalytics } from '~/context/SegmentProvider'

const Login = () => {
  const thatchSegment = useAnalytics()

  const origDestination = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('destination')
      ? decodeURIComponent(params.get('destination') as string)
      : undefined ?? '/'
  }

  return (
    <LoginHome
      onEmailAuthSuccess={uid => {
        thatchSegment.trackAuthEvent(AuthEventsEnum.enum.SignUpCompleted, {
          button_clicked: 'menu_log_in',
          source_url: window.location.href,
          user_uid: uid,
        })
      }}
      authRedirectEventPayload={{
        button_clicked: 'menu_log_in',
        source_url: window.location.href,
        [FINAL_DESTINATION_PARAM]: origDestination(),
      }}
    />
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <Layout hideFooter>{page}</Layout>
}

export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenAuthedBeforeRedirect: AuthAction.SHOW_LOADER,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: FullPageLoader,
})(Login)
